
import { defineComponent, ref } from 'vue';
import InvoiceReceipt from '@/components/Accounting/InvoiceReceipt.vue';

export default defineComponent({
    props: {
        orderId: {
            type: String,
            required: true
        },
        invoiceReceiptId: {
            type: String,
            required: true
        }
    },
    components: {
        InvoiceReceipt
    },
    async setup() {
        return {};
    }
});
